import React from "react";
import "../index.css";
import VdoStoreManagement from "../../../../static/deeple-store-management_ipad.av1.mp4";

type CardPropsType = {
  children?: React.ReactNode;
};

const CardScrollerStoreManagement: React.FC<CardPropsType> = () => {
  const cardDetails = [
    {
      text1: "Full-Function Store Management",
      text2:
        "ช่วยคุณดูแลแชทจากทุกแพลตฟอร์ม จัดการออเดอร์ สต็อกสินค้า พร้อมดูแลลูกค้าจนจบการขาย",
    },
  ];
  return (
    <div className="relative pb-8">
      <div className="card-scroller-container p-6">
        <div>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            ระบบจัดการหลังร้านครบวงจร&nbsp;
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            ให้ทุกการซื้อ-ขายง่ายขึ้น
          </span>
        </div>
      </div>
      {cardDetails.map((card) => {
        return (
          <div key={card.text1}>
            <div className="store-management-wrapper flex items-end bg-black">
              <div className="dp-card-content-info store-management-text-info-wrapper">
                <h3 className="dp-card-content-eyebrow">{card.text1}</h3>
                <div className="dp-card-content-header">
                  <a className="dp-card-content-header-link">
                    <div className="text-white">{card.text2}</div>
                  </a>
                </div>
              </div>
              <div
                className="flex justify-center item-center w-full"
                style={{ height: "90%" }}
              >
                <video width="auto" height="830" autoPlay muted loop>
                  <source src={VdoStoreManagement} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardScrollerStoreManagement;
